import React from "react";

export const About=()=>{
    //explaination of the context api
    // console.log("about page");
    // const a=useContext(NoteContext);
    // console.log(a.update());
    // useEffect(()=>{
    //     a.update();
    //   //  react-hooks/exhaustive-deps
    // },[]);
    //{a.state.name} {a.state.no} 
    return(
        <div>
            This is About page 
        </div>
    )
}
export default About;