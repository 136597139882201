
import React from "react";
import Notes from './Notes';
export const Home=()=>{
    return(
        <div>
           <Notes/>
          
        </div>
    )
}

export default Home;